import request from '@/utils/request'

/**
 * 获取标签列表
 * @returns 
 */
export function getLabel(data) {
  return request({
      url: '/label/select',
      method: 'GET',
      params:data
  })
}

/**
 * 添加 & 修改 标签
 */
export function insertion(data) {
  return request({
      url: '/label/insertion',
      method: 'POST',
      data
  })
}

/**
 * 删除
 * @returns 
 */
export function del(data) {
  return request({
      url: '/label/del',
      method: 'POST',
      data
  })
}
