<template>
  <div class="home">
    <div class="left">
      <!-- 鸡汤 -->
      <div class="chickenSoup">
        <i class="iconfont" style="color: red; margin-right: 5px">&#xe605;</i
        >{{ SoulChicken }}
      </div>

      <el-carousel class="carous">
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <!-- <h3 class="small justify-center" text="2xl">{{ item }}</h3> -->
          <router-link
            to=""
            @click="Details(item.articleId, item.articleTitle)"
          >
            <img
              :src="'https://api.lphadmin.cn/uploads/' + item.Banner"
              alt=""
            />
            <p style="">{{ item.articleTitle }}</p>
          </router-link>
        </el-carousel-item>
      </el-carousel>

      <!-- 文章列表 -->
      <div class="ArticleList">
        <div
          class="ArticleItem"
          v-for="(item, index) in ArticleList"
          :key="index"
        >
          <router-link
            v-if="item.thumb != ''"
            class="Image"
            to=""
            @click="Details(item.articleId, item.articleTitle)"
          >
            <img
              :src="'https://api.lphadmin.cn/uploads/' + item.thumb"
              alt=""
            />
          </router-link>
          <h2 class="Title">
            <router-link
              to=""
              @click="Details(item.articleId, item.articleTitle)"
              >{{ item.articleTitle }}</router-link
            >
          </h2>
          <div class="Digest">{{ item.articleDigest }}</div>
          <div class="Details">
            <p>
              <span
                v-if="item.Sticky === 1"
                class="icode"
                style="
                  color: rgb(255, 114, 66);
                  display: flex;
                  align-items: center;
                "
                ><i class="iconfont" style="margin-right: 4px">&#xe890;</i>
                置顶</span
              >
              <span class="icode"
                ><i class="iconfont">&#xe600;</i> {{ item.creationTime }}</span
              >
              <span class="icode"
                ><i class="iconfont">&#xe6a7;</i>
                {{ item.articleClassify }}</span
              >
              <span class="icode">
                <a v-for="(items, index) in item.articleLable" :key="index">
                  <span v-for="(i, index) in labelList" :key="index">
                    <el-tag
                      v-if="items === i.labelId"
                      :style="{
                        color: i.labelColor,
                        backgroundColor: i.labelBgc,
                        borderColor: i.labelBgc,
                      }"
                      @click="labelClick(i)"
                      >{{ i.labelName }}</el-tag
                    >
                  </span>
                </a>
              </span>
            </p>
            <a @click="Details(item.articleId, item.articleTitle)">阅读全文</a>
          </div>
        </div>
      </div>
      <div class="paging">
        <span v-if="formArticle.page != 1" @click="nextPage('page')"
          >上一页</span
        >
        <span
          v-for="item in pages"
          :key="item"
          @click="breaks(item)"
          :class="{
            active: formArticle.page === item,
          }"
          >{{ item }}</span
        >
        <span v-if="formArticle.page != pages" @click="nextPage('next')"
          >下一页</span
        >
      </div>
    </div>
  </div>
</template>

<script setup name="Home">
import { ref, onMounted } from "vue";
import { getArticle, random, getSticky } from "@/api/article";
import { getLabel } from "@/api/label";
import { useStore } from "vuex";
import router from "@/router";
const store = useStore();
const SoulChicken = ref("");
onMounted(() => {
  getLabels(); // 获取标签
  getBanner(); // 获取轮播
  // getStickyList(); // 置顶文章
  getRandom(); // 获取鸡汤
  getArticleList(); // 获取文章列表
});

// 文章搜索条件
const formArticle = ref({
  limit: 5,
  page: 1,
  iScarousel: 0,
  Sticky: 0,
  // articleTitle: "addEventListener",
  // articleLable: "[726719]",
});

// 随机获取鸡汤
const getRandom = () => {
  random().then((res) => {
    SoulChicken.value = res.data.chicken;
  });
};

// 文章总数
const articleNum = ref(0);
// 文章列表
const ArticleList = ref([]);
const getArticleList = () => {
  let obj = { ...formArticle.value };
  for (let key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  getArticle(obj).then((res) => {
    if (res.code === 200) {
      articleNum.value = res.data.total;
      store.dispatch("get_articlesNum", res.data.count);
      store.dispatch("get_turnoverTime", res.data.data[0].creationTime);
      ArticleList.value = [];
      if (formArticle.value.page === 1) {
        getStickyList();
      }
      res.data.data.forEach((v) => {
        v.articleLable = JSON.parse(v.articleLable);
        ArticleList.value.push(v);
      });
    }
  });
};

const pages = ref();
// 分页数
const pageImgNum = () => {
  pages.value = Math.ceil(articleNum.value / 5);
};

// 分页按钮
const breaks = (num) => {
  formArticle.value.page = num;
  getArticleList();
};
const nextPage = (type) => {
  if (type === "page") {
    formArticle.value.page = formArticle.value.page - 1;
  } else {
    formArticle.value.page = formArticle.value.page + 1;
  }
  getArticleList();
};

// 置顶数量
const stickyNum = ref(0);
// 获取置顶文章
const getStickyList = () => {
  getSticky().then((res) => {
    if (res.code === 200) {
      stickyNum.value = res.data.length;
      res.data.forEach((v) => {
        v.articleLable = JSON.parse(v.articleLable);
        ArticleList.value.unshift(v);
      });
      pageImgNum();
    }
  });
};

// 获取轮播
const bannerNum = ref(0);
const Banner = ref([]);
const getBanner = () => {
  let obj = {
    iScarousel: 1,
  };
  getArticle(obj).then((res) => {
    if (res.code === 200) {
      bannerNum.value = res.data.data.length;
      res.data.data.forEach((v) => {
        v.articleLable = JSON.parse(v.articleLable);
        Banner.value.push(v);
      });
    }
  });
};

// 获取标签
const labelList = ref([]);
const getLabels = () => {
  getLabel().then((res) => {
    store.dispatch("get_tagNum", res.data.count);
    if (res.code === 200) {
      labelList.value = res.data.data;
    }
  });
};

// 跳转详情
const Details = (id, title) => {
  let obj = {
    id,
    title,
  };
  store.dispatch("sum_setArticleId", obj);
  router.push({
    path: "/details",
    query: {
      id,
    },
  });
};
// label点击
const labelClick = (i) => {
  console.log(i);
  router.push({
    path: "/search",
    query: {
      type: i.labelId,
      typeLable: i.labelName,
    },
  });
};
onMounted(() => {
  document.title = "小情绪";
});
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  .left {
    width: 100%;
    .carous {
      margin-top: 20px;
      border-radius: 4px;
      background-color: pink;
      position: relative;
      a {
        img {
          width: 100%;
          height: 100%;
        }
        p {
          width: 100%;
          padding: 10px 0;
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          bottom: 8%;
          left: 0;
          text-align: center;
          color: #fff;
          font-weight: 700;
        }
      }
    }
    .chickenSoup {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background-color: #fff;
      font-weight: 400;
      border-radius: 4px;
      font-size: 16px;
    }
    .ArticleList {
      .ArticleItem {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        margin-top: 20px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 16px;
        .Image {
          display: flex;
          justify-content: center;
          border-radius: 4px;
          width: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 320px;
            transition: transform 3s ease-out, opacity 0.5s ease-out;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
        .Title {
          padding: 15px 0 10px 10px;
          a {
            text-decoration: none;
            font-size: 22px;
            color: #333;
          }
        }
        .Digest {
          color: #333;
          opacity: 0.8;
          font-size: 18px;
          padding: 0 0 0 10px;
        }
        .Details {
          padding: 20px 10px 10px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #333;
          opacity: 0.8;
          font-size: 16px;
          p {
            display: flex;
            align-items: center;
            .icode {
              margin-right: 10px;
              a {
                margin-right: 5px;
                cursor: pointer;
              }
            }
          }
          a {
            cursor: pointer;
            text-decoration: none;
            font-weight: 700;
            color: #409eff;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .paging {
      margin-top: 20px;
      width: 100%;
      box-sizing: border-box;
      padding: 16px;
      background-color: #fff;
      text-align: center;
      span {
        padding: 0 10px;
        margin: 0 5px;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid #e8e8e8;
        }
      }
      .active {
        color: #409eff;
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .home .left .ArticleList .ArticleItem .Image img {
    height: 300px;
  }
}
@media screen and (max-width: 540px) {
  .el-carousel__container {
    height: 230px;
  }
  .home .left .ArticleList .ArticleItem .Image img {
    height: 220px;
  }
  .home .left .ArticleList .ArticleItem .Digest {
    font-size: 16px;
  }
  .home .left .ArticleList .ArticleItem .Details {
    font-size: 14px;
    & > a {
      display: none;
    }
  }
}
@media screen and (max-width: 414px) {
  .el-carousel__container {
    height: 200px;
  }
  .home .left .ArticleList .ArticleItem .Image img {
    height: 180px;
  }
  .home .left .ArticleList .ArticleItem .Details > a {
    display: none;
  }
}
</style>
