import request from '@/utils/request'

/**
 * 获取文章列表
 * @returns 
 */
export function getArticle(data) {
  return request({
      url: '/article/select',
      method: 'GET',
      params:data
  })
}

/**
 * 获取文章详情
 * @param {*} data 
 * @returns 
 */
export function getDetails(data) {
  return request({
      url: '/article/select',
      method: 'POST',
      data
  })
}

/**
 * 获取置顶文章
 * @returns 
 */
export function getSticky() {
  return request({
      url: '/article/sticky',
      method: 'GET',
  })
}

/**
 * 添加点击数
 * @returns 
 */
export function hit(data) {
  return request({
      url: '/article/hit',
      method: 'POST',
      data
  })
}


/**
 * 随机获取鸡汤文学
 * @returns
 */
export function random(data) {
  return request({
      url: '/chicken/random',
      method: 'POST',
      data
  })
}